<template>
  <div class="bindtelphone">
    <login-header></login-header>
    <div>
      <div class="bindtitle">完善用户信息</div>

      <div class="action">
        <el-form
          class="form"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="90px"
        >

          <el-form-item
            class="inputitem"
            prop="truename"
            :rules="rules.truename"
            label="姓名"
          >
            <el-input
              placeholder="请输入真实姓名"
              v-model="ruleForm.truename"
            ></el-input>
          </el-form-item>

          <el-form-item
            class="inputitem"
            prop="company"
            :rules="rules.company"
            label="单位"
          >
            <el-input
              placeholder="请输入单位名称"
              v-model="ruleForm.company"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="inputitem"
            prop="job"
            :rules="rules.job"
            label="职位"
          >
            <el-input
              placeholder="请输入职位名称"
              v-model="ruleForm.job"
            ></el-input>
            
          </el-form-item>
          <el-form-item
            class="inputitem"
            prop="password"
            :rules="rules.password"
            label="设置密码"
          >
            <el-input
              type="password"
              placeholder="请输入密码"
              v-model="ruleForm.password"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="inputitem"
            prop="password2"
            :rules="rules.password2"
            label="确认密码"
          >
            <el-input
						   type="password"
              placeholder="请再次输入密码"
              v-model="ruleForm.password2"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="inputitemxieyi"
    
          >
             <span>
                  <input type="checkbox" class="agree" v-model="agree"  style="position: relative; top:2px;"/>
                  已阅读并同意 <span class="xieyi" @click="toxieyi" >《土拍网用户协议&网站使用条款》</span></span>

            </el-form-item>
          <el-form-item
            label=" "
            class="inputitem"
          >
            <el-button
              type="primary"
              class="btnsubmit"
              @click="submitForm('ruleForm')"
            >提 交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import loginHeader from "@/components/login/loginheader";
import { saveProfile } from "@/api/login";
export default {
  components: { loginHeader },
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
        // password 是表单上绑定的字段
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      timer: 0,
      ruleForm: {
        truename: "",
        company: "",
        job: "",
        password: "",
        password2: "",
      },
      agree:false,

      rules: {
        truename: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        company: [{ required: true, message: "公司不能为空", trigger: "blur" }],
        job: [{ required: true, message: "职位不能为空", trigger: "blur" }],
        password: [
          { required: true,message: "密码不能为空", trigger: "blur" },
          // { min:6,max:20,  pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/,   message: "密码格式为6-20位字母和数字组合", trigger: "blur" }

        ],

        password2: [
           { required: true, validator: validatePass2, trigger: 'blur' }
        ],
      },
    };
  },

  computed: {
   
    membersId: {
      get() {
        return this.$store.state.membersId;
      },
      set(v) {
        this.$store.commit("setMembersId", v);
      },
    },
  },
  mounted() {},

  methods: {

    toxieyi(){
      window.open('/#/xieyi')
    },


    changePCMode(v) {
      this.loginMode = v;
    },

    submitForm() {
      var that = this;
      this.$refs.ruleForm.validate(function (valid) {
        
        if (valid) {
          if(that.agree){
            
        

					const params = that.ruleForm
					delete(params.password2)
          params.members_id = that.membersId
        // frontend/members/profile
          saveProfile(params).then((res) => {
            console.log('saveProfile',res)
            
              //登录成功
              const token = res.data.token;
              // that.$store.commit('setToken',token)
          
              that.$store.dispatch('setUserinfo',token);
              
							// that.$router.push('/')
              

          });
        }else{
          that.$message.info('请同意并勾选用户协议')
        }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bindtelphone {
  height: 100vh;
  width: 100%;
  background: #f0f3f8;
}
.xieyi{
  color:#50b6e5;
  cursor: pointer;
}

.bindtitle {
  width: 80%;
  height: 50px;
  background: #ffffff;
  line-height: 50px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: left;
  padding-left: 20px;
  box-sizing: border-box;
}
.action {
  width: 80%;
  height: 488px;
  background: #ffffff;
  margin: 0 auto;
  padding-top: 10px;
  padding-left: 5px;
  box-sizing: border-box;
  .form {
    .inputitem {
      width: 420px;
      margin-top: 20px;
      text-align: left;
    }
    .inputitemxieyi{
      width:800px;
      margin-top: 20px;
      text-align: left;
    }
    
    .btnsubmit {
      width: 120px;
    }
  }
}

.inputslot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85px;
  .msgBtn {
    width: 90px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #28a7e1;
    cursor: pointer;
  }
  .line {
    height: 14px;
    width: 1px;
    background: #c5c5c5;
  }
}
</style>